import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import QRCode from 'qrcode.react'
import styled from 'styled-components'
import PoweredByImage from '../assets/powered-by.png'

import { Logo, PoweredBox, PoweredBy, SubmitBtn } from './CommonStylesForms'

const CompFormVerify = styled.div`
  box-sizing: border-box;
  padding-top: 30px;
`
const IntroFormVerify = styled.div`
  box-sizing: border-box;
`
const FormWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2%;
  background: white;
`
const HeaderVerify = styled.div`
  color: ${(props) => props.theme.primary_color};
  border-bottom: 2px solid #ddd;
  margin-bottom: 30px;
  font-size: 1.9em;
  padding-top: 1em;
  padding-bottom: 3px;
`
const VerifyIntro = styled.div`
  color: ${(props) => props.theme.primary_color};
  margin-bottom: 30px;
  font-size: 1.5em;
`
const QR = styled(QRCode)`
  display: block;
  margin: auto;
  padding: 20px;
  width: 300px;
  height: 300px;
  background-color: white;
`
const VerChoiceWrapper = styled.span`
  display: flex;
  width: 50%;
  justify-content: space-evenly;
`

function Root(props) {
  const [waitingForInvitation, setWaitingForInvitation] = useState(false)
  const [waitingForConnection, setWaitingForConnection] = useState(false)
  const [waitingForVerification, setWaitingForVerification] = useState(false)
  const [connected, setConnected] = useState(false)
  const [chosenCred, setChosenCred] = useState('')
  const contactsState = useSelector((state) => state.contacts)
  const settingsState = useSelector((state) => state.settings)
  const logo = settingsState.logo

  useEffect(() => {
    if (
      !waitingForInvitation &&
      props.anonWebsocket &&
      props.readyForAnonMessages
    ) {
      props.sendRequest('INVITATIONS', 'CREATE_SINGLE_USE', {})
      setWaitingForInvitation(true)
    }
  }, [props.anonWebsocket, props.readyForAnonMessages])

  useEffect(() => {
    if (props.QRCodeURL !== '') {
      setWaitingForConnection(true)
    }
    if (contactsState.connection.state === 'active' && waitingForConnection) {
      setConnected(true)
    }
  }, [props.QRCodeURL, contactsState.connection, waitingForConnection])

  const requestPresentations = (cred) => {
    setChosenCred(cred)
    switch (cred) {
      case 'EMAIL':
        props.sendRequest('PRESENTATIONS', 'REQUEST', {
          connection_id: contactsState.connection.connection_id,
          schema_id: settingsState.schemas.SCHEMA_EMAIL,
          attributes: ['address'],
          discovered_features: contactsState.connection.discovered_features,
        })
        setWaitingForVerification(true)

        break
      case 'EMPLOYMENT':
        props.sendRequest('PRESENTATIONS', 'REQUEST', {
          connection_id: contactsState.connection.connection_id,
          schema_id: settingsState.schemas.SCHEMA_EMPLOYMENT,
          attributes: [
            'employee_given_names',
            'employee_surnames',
            'employment_type',
            'employment_postal_code',
            'employment_country',
            'employment_address',
            'employment_region',
            'employment_city',
            'employment_start_date',
            'employment_role',
            'employment_role_description',
            'employer_address',
            'employer_postal_code',
            'employer_city',
            'employer_country',
            'employer_region',
            'employer_name',
          ],
          discovered_features: contactsState.connection.discovered_features,
        })
        setWaitingForVerification(true)
        break
        case 'KYC':
          props.sendRequest('PRESENTATIONS', 'REQUEST', {
            connection_id: contactsState.connection.connection_id,
            schema_id: settingsState.schemas.SCHEMA_KYC,
            attributes: [
          'given_names',
          'gender_legal',
          'credit_score_agency',
          'government_id_expiration',
          'employment_previous_employer',
          'edd_performed',
          'date_of_birth',
          'risk_rating',
          'sdd_performed',
          'postal_code',
          'accounts_verified',
          'income_annual',
          'accounts_sum_of_balances_current',
          'employment_current_employer',
          'credential_issued_date',
          'government_id_type',
          'proof_of_address_method',
          'employment_previous_verified',
          'employment_current_start_date',
          'government_id_number',
          'employment_previous_start_date',
          'employment_current_verified',
          'surnames',
          'mobile_phone_number',
          'email',
          'state_province_region',
          'credit_score',
          'blacklists_checked',
          'accounts_sum_of_balances_6_month_average',
          'street_address',
          'proof_of_address_verified',
          'income_currency',
          'city',
          'income_verified',
          'photograph',
            ],
            discovered_features: contactsState.connection.discovered_features,
          })
          setWaitingForVerification(true)
  
          break
      
      default:
        return
    }
  }

  const verifiedDisplay = () => {
    switch (chosenCred) {
      case 'EMAIL':
        return <p>Email: {props.verifiedCredential.address.raw}</p>
      case 'EMPLOYMENT':
        return (
          <span>
            <p>
              Given Names: {props.verifiedCredential.employee_given_names.raw}
            </p>
            <p>Surnames: {props.verifiedCredential.employee_surnames.raw}</p>
            <p>Employer Name: {props.verifiedCredential.employer_name.raw}</p>
          </span>
        )
        case 'KYC':
        return (
          <IntroFormVerify>
            <FormWrapper>
              <VerifyIntro>Welcome {props.verifiedCredential.given_names.raw}! </VerifyIntro>
              <VerifyIntro>Select an Option Below to Begin Your Application:</VerifyIntro>
            <div className = "icon-row">
              <div className="one-third-column">
                <span>
                  <h3>Auto</h3>
                </span>
                <div className="auto-icon"></div>
              </div>
              <div className="one-third-column">
                <span>
                  <h3>Home</h3>
                </span>
                <div className="home-icon"></div>      
              </div>
              <div className="one-third-column">
                <span>
                  <h3>Life</h3>
                </span>
                <div className="life-icon"></div>
              </div>
          </div>
          </FormWrapper>
        </IntroFormVerify>
        )
      default:
        return <p>Credential Data could not be displayed</p>
    }
  }

  return (
    <>
      <CompFormVerify>
        <FormWrapper>
          <div className="avatar-container left-fold landing-col-6">
            {logo ? <Logo src={logo} alt="Logo" /> : <Logo />}
          </div>
          {connected ? (
            waitingForVerification ? (
              props.verificationStatus !== undefined ? (
                props.verificationStatus ? (
                  props.verifiedCredential ? (
                    <>
                      <HeaderVerify>Verified: KYC Credential from Mountain Banking</HeaderVerify>
                      {verifiedDisplay()}
                    </>
                  ) : (
                    <>
                      <HeaderVerify>Credentials Verified!</HeaderVerify>
                      <p>No Credential Data Was Passed</p>
                    </>
                  )
                ) : (
                  <>
                    <HeaderVerify>Verification Failed</HeaderVerify>
                    <p>
                      There was a problem verifying your credential. Please try
                      again or contact support by clicking on the link below:
                    </p>
                    <a href="https://indicio.tech/proven-support/">
                      <SubmitBtn>Contact Support</SubmitBtn>
                    </a>
                  </>
                )
              ) : (
                <>
                  <HeaderVerify>Verify your Credentials</HeaderVerify>
                  <p>
                    You will now receive a request on your mobile app to send
                    your credential to us for verification.
                  </p>
                </>
              )
            ) : (
              <>
                <HeaderVerify>Choose a credential to verify</HeaderVerify>
                <VerChoiceWrapper>
                  {/* <SubmitBtn onClick={() => requestPresentations('EMAIL')}>
                    Email
                  </SubmitBtn>
                  <SubmitBtn onClick={() => requestPresentations('EMPLOYMENT')}>
                    Employment
                  </SubmitBtn> */}
                  <SubmitBtn onClick={() => requestPresentations('KYC')}>
                    KYC
                  </SubmitBtn>
                </VerChoiceWrapper>
              </>
            )
          ) : (
            <>
              <HeaderVerify>Verify your Credentials</HeaderVerify>
              <p>
                Simply scan the following QR code to begin the verification
                process:
              </p>
              {props.QRCodeURL ? (
                <div className="qr">
                  <p>
                    <QR value={props.QRCodeURL} size={256} renderAs="svg" />
                  </p>
                </div>
              ) : (
                <p>
                  <span>Loading...</span>
                </p>
              )}
            </>
          )}
        </FormWrapper>
        <PoweredBox>
          <PoweredBy src={PoweredByImage} alt="Powered By Indicio" />
        </PoweredBox>
      </CompFormVerify>
    </>
  )
}

export default Root
