import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

import PageHeader from './PageHeader'
import PageSection from './PageSection'

export const HelpBtn = styled.button`
  margin: auto;
  margin-top: 15px;
  margin-right: 20px;
  padding: 10px 20px;
  font-size: 0.8em;
  text-transform: uppercase;
  color: ${(props) => props.theme.text_light};
  border: none;
  box-shadow: ${(props) => props.theme.drop_shadow};
  background: ${(props) => props.theme.primary_color};
  :hover {
    cursor: pointer;
  }
`

const HelpHeader = styled.h2`
  display: inline-block;
  margin-right: 10px;
`

const StyledLink = styled(NavLink)`
margin-top: 30px;
margin-right: 20px;
padding: 10px 20px;
font-size: 0.8em;
text-transform: uppercase;
text-decoration: none;
color: ${(props) => props.theme.text_light};
border: none;
box-shadow: ${(props) => props.theme.drop_shadow};
background: ${(props) => props.theme.primary_color};
:hover {
  cursor: pointer;
}
`

function Help() {
  let pathMatch = ''
  // if (props.match.path !== undefined) {
  //   pathMatch = props.match.path
  // }
  
  return (
    <div>
      <PageHeader title={'Help'} />
      <PageSection>
        <HelpHeader>User's Guide</HelpHeader>
        <span>
          <br />
          One of the best ways to get started with Proven is to read the user’s
          guide. Click the link below to open the user's guide.
          <br /><br/>
        </span>
            <StyledLink exact to="/admin/help/users-guide" className={pathMatch === '/users-guide' ? 'active' : undefined}>
              User's Guide
            </StyledLink>
      </PageSection>
      <PageSection>
        <HelpHeader>FAQ</HelpHeader>
        <span>
          <br />
          Follow the link below to open a list of frequently asked questions
          about how to configure, integrate, use, and troubleshoot Proven.
          <br />
        </span>
        <a
          href="https://indicio.tech/proven-support/"
          target="_blank"
          rel="noreferrer"
        >
          <HelpBtn>FAQ</HelpBtn>
        </a>
      </PageSection>
      <PageSection>
        <HelpHeader>Contact Support</HelpHeader>
        <span>
          <br />
          You can get help with Proven by filling out our support request form.
          Your request will be answered according to the support plan you
          subscribe to.
          <br />
        </span>
        <a
          href="https://indicio.tech/proven-support/"
          target="_blank"
          rel="noreferrer"
        >
          <HelpBtn>Contact Support</HelpBtn>
          <HelpBtn>Support Plans</HelpBtn>
        </a>
      </PageSection>
    </div>
  )
}

export default Help