import React from 'react'
import styled from 'styled-components'

import PageHeader from './PageHeader'
import PageSection from './PageSection'

const GuideHeader = styled.h2`
  display: inline-block;
  margin-right: 10px;
`

const Bolded = styled.h2`
  font-weight: bold;
  font-size: 100%;
  display: inline;
`

const BulletPoint = styled.h2`
  list-style-type: circle;
`

function UsersGuide() {
  
  return (
    <div>
        <PageHeader title={"Proven Verifier User's Guide"} />
        <PageSection>
        <GuideHeader>
            Overview
        </GuideHeader>
        <br/>
        Proven provides the tools to establish a Trusted Digital Ecosystem (TDE). Out of the box, you can issue, verify, and manage email credentials. Once you have an email credential, other workflows become possible because you can trust you are working with a known person or agent.

        The issuer’s role is to send invitations to verify the contacts’ (email) information goes to the intended users. The issuer also manages contacts.
        </PageSection>

        <PageSection>
        <GuideHeader>
            Home
        </GuideHeader>
        <br/>
        After logging into the Admin screen, you will land on the home page. Connect to a new user by clicking <Bolded>Email Invitation.</Bolded> <br/><br/>

        Note: It is possible for a person to issue their own credential invitation. On the login screen, there is a button that reads, “Go to email issuer.” If that button is pushed, then they can provide their own email address.
        </PageSection>

        <PageSection>
        <GuideHeader>
            Email Invitation
        </GuideHeader>
        <br/>
        To connect to the the mobile app user, send an email invitation: <br/>
        <br/>

        1. Click <Bolded>Email Invitation</Bolded>. <br/>
        2. Enter the invitee’s <Bolded>Email Address</Bolded>.<br/>
        3. Click <Bolded>Send</Bolded>.<br/><br/>

        After receiving their email invitation, the user opens the email and follows the personalized link. <br/><br/>

        The mobile app user now needs to open the app on their device, scan the QR code, and accepts the email credential offer.
        </PageSection>

        <PageSection>
        <GuideHeader>
            Contacts
        </GuideHeader>
        <br/>
        Contacts are connections between the Proven Issuer and other agent applications. Some of these agents are used by real people and some operate autonomously. These are used and managed by real people. Not all software includes an editable label; therefore, some contacts will show the name of the software rather than the name of the person. The following information is displayed when first clicking into <Bolded>Contacts:</Bolded> <br/><br/>

        <Bolded>Contact Name</Bolded>: the name of the mobile app user <br/><br/>
        <Bolded>Connection Status</Bolded>:
        <ul>
            <li>invite: an invitation has been sent</li>
            <li>request: the second party has recieved the invite and is requesting a connection</li>
            <li>response: this system has responded with details to finish setting up the connection</li>
            <li>active: the second party has acknowledged the connection</li>
        </ul>
        <Bolded>Created At</Bolded>: the date and time the inviation was sent

        Click on a specific contact to view more information. At the bottom of the screen, there is a section of all credentials issued to that user. <br/><br/>

        To view more detailed information on any issued credential, click on it. This displays things such as the credential name, ID, state, and date created. It also displays certain attributes, such as the different parts of email addresses and the date the credential was validated.
        </PageSection>

        <PageSection>
        <GuideHeader>
            Credentials
        </GuideHeader>
        <br/>
        A credential is encrypted data. When the <Bolded>Credentials</Bolded> tab is displayed it shows the following: 
    
        <ul>
        <li><Bolded>Offer Sent</Bolded>: credential created and a connection is sent to mobile app user </li> 
        <li><Bolded>Credential Acked</Bolded>: mobile app user has acknowledged the offer and sent a request for a credential</li>        
        <li><Bolded>Credential Issued</Bolded>: the system has issued the credential        </li>
        </ul>
        Click on any credential to view more information.         
        </PageSection>

        <PageSection>
        <GuideHeader>
            Users
        </GuideHeader>
        <br/>
        The <Bolded>User</Bolded> tab provides a list of all users that have access to the Proven Issuer admin system. New users can be created by anyone with Admin role. <br/><br/>

        <Bolded>Create new use</Bolded>r: <br/><br/>
        1. Click on (+) found in the bottom right corner.<br/>
        2. Enter the <Bolded>Email</Bolded>.<br/>
        3. Select the <Bolded>Roles</Bolded>:<br/>
        admin: gives all rights to the issuer<br/>
        technician: limits to adding contacts and issuing credentials<br/>
        4. Click <Bolded>Submit</Bolded>.<br/><br/>
        Invitations are only good for 24 hours after they are issued. If the new user doesn’t sign in within that time period, press the envelope in the <Bolded>Resend</Bolded> column to reissue the invite.<br/><br/>

        Usernames are established as new users log in for the first time.<br/><br/>


        <Bolded>Edit users</Bolded>:<br/><br/>
        1. Click the edit symbol. insert inline edit symbol here if possible<br/>
        2. Edit the desired fields: email, username, roles.<br/>
        3. Click Submit.<br/><br/>
        
        <Bolded>Delete users</Bolded>:<br/><br/>
        If you wish to delete a user, follow the steps below:<br/><br/>

        1. Click delete symbol. insert inline delete symbol here if possible<br/>
        2. Click Remove to confirm you want to delete the user     <br/>
        </PageSection>

        <PageSection>
        <GuideHeader>
            Settings
        </GuideHeader>
        <br/>
        In Settings, an admin can customize the screen to match the branding of the organization. Below are the options and descriptions:         <br/><br/>


<Bolded>Organization Details</Bolded>:        

<ul>
<li>Organization Name: name as it appears below the logo on the left</li>
<li>Website Title: name as it appears on the browser’s tab </li>
</ul>
<Bolded>Change Logo</Bolded>: <br/>
This takes any properly formatted image file which is transparent or has a background that matches the admin system background (the default background color is white, #ffffff).

<ul>
<li>Change Logo: on email stream and on the left</li>
<li>Change Logo 192 x 192: used when a mobile device is used instead of a desktop</li>
<li>Change Logo 512 x 512: used for mobile devices</li>
<li>Update favicon.ico: image found next to the name in the browser’s tab</li>
</ul>
<Bolded>Web App Manifest</Bolded>:<br/>
The web app manifest provides app information to devices which treat the admin portal as a single-page application (such as mobile devices).

<ul>
<li>Short name</li>
<li>Full name</li>
<li>Theme color</li>
<li>Background color</li>
</ul>
<Bolded>SMTP Configuration</Bolded>:<br/>
This is the email account used when sending credentials and invitations to users. It must be set up before any credential invitations can be issued. If your company has a no-reply email, it is acceptable to use it here.

<ul>
<li>Host: mandatory field - the hostname or IP address to connect to (defaults to ‘localhost’)</li>
<li>Mail Username: mandatory field - the username (for Gmail accounts, mail username must be the same as the user email, e.g. johndoe@example.com)</li>
<li>User email: mandatory field - the user email</li>
<li>User password: mandatory field - the password for the email account (or an app password if a Gmail account is used)</li>
<li>Port: optional field - the port which the email system uses to accept email sending requests (defaults to 587 if Encryption Type is false or 465 if true).</li>
<li>Encryption: optional field - if true the connection will use TLS when connecting to server. If false (the default), then TLS is entered if the server supports the STARTTLS extension. In most cases, set this value to true if you are connecting to port 465. For port 587 or 25 keep it false.</li>
</ul>

<Bolded>Theme</Bolded>:<br/><br/>
The theme changes the colors of the different elements. Below is a list of the possibilities and the elements affected:

<ul>
<li>primary color: action buttons (submit, confirm, save, edit, etc.), current tab color, and username</li>
<li>secondary color: hover-over tab, tooltip symbols</li>
<li>tertiary color: home page hover-over color</li>
<li>neutral color: disabled buttons and form elements</li>
<li>negative colors: warnings that pop up, delete buttons, cancel buttons</li>
<li>warning color: undo buttons</li>
<li>positive color: successes that pop up</li>
<li>text color: all text with the exception of tooltips</li>
<li>text light: text on buttons</li>
<li>border: most of the border lines found in the application; it uses a full CSS declaration, such as “1px solid #ddd”</li>
<li>drop shadow: some of the various boxes in the application; they require a full CSS declaration, such as “3px 3px 3px rgba(0, 0, 0, 0.3)”</li>
<li>primary background: background of all the boxes</li>
<li>secondary background: every other row of the tables</li>
</ul>

Click <Bolded>Save All</Bolded> when all desired changes are made.     
        </PageSection>

    </div>
  )
}

export default UsersGuide